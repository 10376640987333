import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import useAltoClient from '../api/UseAltoClient'

export const AuthContext = React.createContext({})

const PENDING = 'pending'

const AuthProvider = ({children}) => {
  // Increment nonce to retry requests/reconnect websockets after refreshing auth.
  const [{auth, authNonce}, setAuthAndNonce] = useState({auth: PENDING, nonce: 0})

  const {whoAmI, logout, authenticate, useRefreshToken} = useAltoClient()
  const history = useHistory()

  const checkAuth = async () => {
    try {
      setAuthAndNonce({auth: await whoAmI(), nonce: authNonce + 1})
    } catch (e) {
      await logoutUser()
    }
  }

  useEffect(() => {
    checkAuth()
  }, [])

  const loginUser = async ({username, password, organisationId}) => {
    await authenticate(username, password, organisationId)
  }

  const refreshAuth = async (organisationId) => {
    setAuthAndNonce({auth: PENDING, nonce: authNonce})
    await useRefreshToken(organisationId)
  }

  const logoutUser = async () => {
    setAuthAndNonce({auth: undefined, nonce: authNonce})
    await logout()
    history.push('/login')
  }

  return (
    <AuthContext.Provider value={{auth, refreshAuth, authNonce, loginUser, logoutUser}}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
