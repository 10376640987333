import React, {useContext, useState} from 'react'
import useDarkMode from './DarkMode'

export const ConfigContext = React.createContext({})
const CONFIG_KEY = 'com.blackalto.config'

export const ConfigProvider = ({children}) => {
  const {darkMode} = useDarkMode()

  const [config, _setConfig] = useState(() => defaults(darkMode))

  config.style.pnlColor = it => it > 0 ? config.style.profitColor : config.style.lossColor
  config.style.textColor = darkMode ? 'white' : undefined
  config.style.lineColor = darkMode ? '#999999ff' : undefined
  config.style.textColorLight = darkMode ? '#22222f' : undefined

  const setConfig = newConfig => {
    _setConfig(c => {
      const combined = {...c, ...newConfig}
      localStorage.setItem(CONFIG_KEY, JSON.stringify(combined))
      return combined
    })
  }

  const updateConfig = (key, value) => {
    setConfig({...config, [key]: value})
  }

  return (
    <ConfigContext.Provider value={{darkMode, ...config, setConfig, updateConfig}}>
      {children}
    </ConfigContext.Provider>
  )
}

function defaults() {
  const fromStorage = localStorage.getItem(CONFIG_KEY)
  if (fromStorage)  {
    try {
      return JSON.parse(fromStorage)
    } catch (e) {
      console.error('Error parsing config from storage', e)
    }
  }
  const narrow = document.body.clientWidth < 1000

  const fontFamily = '-apple-system, system-ui, \'Helvetica Neue\', Ubuntu, sans-serif'
  const fontSize = 11
  const profitColor = '#00ff00'
  const lossColor = '#ff0000'

  const style = {fontFamily, fontSize, profitColor, lossColor}

  const hideBalances = false

  return {style, narrow, hideBalances}
}

export const useConf = () =>
  useContext(ConfigContext)
